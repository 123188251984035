import revive_payload_client_pWp0q6Sr3k from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_93PPNhYndh from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bH5lieCasT from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uJYj8LRep1 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rcuTgoprbc from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kLQ9Q8vdD4 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rP5In30Ude from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BoDdUGjKBB from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.34.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_KYLoaujiK2 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_drizzle-orm@0.33.0_@typ_ead4e72f53879b3ffa5ffb5696b250cd/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pXs58Gna6U from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.34.8/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_O0u4zbVYxJ from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.3.1_patch_hash=4f222d185b7f126b559de6118eb629cf5b1dba6cc9b9a8f8_957dcd8a37b85f4f3167a4d3570ecde2/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_aUTvqp5A0t from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_c4OF1Q882V from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_OVMKr7VaEZ from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.34.8__d972ef65ab170cb0e29a6cf9bfe81de8/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_pWp0q6Sr3k,
  unhead_93PPNhYndh,
  router_bH5lieCasT,
  payload_client_uJYj8LRep1,
  navigation_repaint_client_rcuTgoprbc,
  check_outdated_build_client_kLQ9Q8vdD4,
  chunk_reload_client_rP5In30Ude,
  plugin_vue3_BoDdUGjKBB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KYLoaujiK2,
  plugin_pXs58Gna6U,
  primevue_plugin_egKpok8Auk,
  plugin_client_O0u4zbVYxJ,
  switch_locale_path_ssr_aUTvqp5A0t,
  i18n_c4OF1Q882V,
  plugin_OVMKr7VaEZ,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]